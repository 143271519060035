
  import { defineComponent, PropType, ref, watch } from 'vue';
  import { Employee, EmployeeWage } from '../../types/entities';

  export default defineComponent({
    name: 'WageSidemenuComponent',
    components: {
      sidemenu: () =>
        import(/* webpackChunkName: "teamMembersSidemenu" */ '@/modules/calendar/components/sidemenu.component.vue'),
    },
    emits: ['cancel', 'submit', 'update:hourlyRate', 'update:avgMaterialCost', 'update:startDate', 'update:endDate'],
    props: {
      visible: { type: Boolean },
      employee: { type: Object as PropType<Employee | null> },
      wage: { type: Object as PropType<EmployeeWage | null> },
    },
    setup(props, { emit }) {
      const startDateMenuVisible = ref(false);
      const startDateCopy = ref('');
      const onSetStartDate = (): void => {
        emit('update:startDate', startDateCopy.value);
        startDateMenuVisible.value = false;
      };
      const parseStartDateHandler = (value: string | null): void => {
        if (value) {
          startDateCopy.value = JSON.parse(JSON.stringify(value));
        }
      };
      watch(() => props.wage!.startDate, parseStartDateHandler, { immediate: true });
      watch(startDateMenuVisible, (newVal: boolean) => {
        if (!newVal) parseStartDateHandler(props.wage!.startDate);
      });

      const endDateMenuVisible = ref(false);
      const endDateCopy = ref('');
      const onSetEndDate = (): void => {
        emit('update:endDate', endDateCopy.value);
        endDateMenuVisible.value = false;
      };
      const parseEndDateHandler = (value: string | null): void => {
        if (value) {
          endDateCopy.value = JSON.parse(JSON.stringify(value));
        }
      };
      watch(() => props.wage!.endDate, parseEndDateHandler, { immediate: true });
      watch(endDateMenuVisible, (newVal: boolean) => {
        if (!newVal) parseEndDateHandler(props.wage!.endDate);
      });

      return {
        startDateMenuVisible,
        startDateCopy,
        onSetStartDate,
        endDateMenuVisible,
        endDateCopy,
        onSetEndDate,
      };
    },
  });
